import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router';

import { OrderListComponent } from './components/order/order-list/order-list.component';
import { InternalComponent } from './pages/internal/internal.component';

import { LivetrackingComponent } from './components/livetracking/livetracking.component';
import { CurrentOrganizationResolverService } from './services/current-organization-resolver.service';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { OrganizationsResolverService } from './services/organizations-resolver.service';
import { OrganizationPagesComponent } from './components/organization-pages/organization-pages.component';
import { CurrentOrderResolverService } from './current-order-resolver.service';
import { OrdersListResolverService } from './services/order-list-resolver.service';
import { OrderListRedirectComponent } from './components/order-list-redirect/order-list-redirect.component';
import { MenuRouteResolverService } from './services/menu-route-resolver.service';
import { AdminGuard } from './services/guards/admin.guard';
import { GecoPosOrganizationsComponent } from 'app/components/geco-pos-organizations/geco-pos-organizations.component';
import { CurrentOrganizationPipesResolverService } from 'app/services/current-organization-pipes-resolver.service';
import { ManagerGuard } from 'app/services/guards/manager.guard';
import {
  OrderListDetailsComponent
} from 'app/components/order/order-list/order-list-details/order-list-details.component';

const appRoutes: Routes = [
  {
    path: 'app',
    component: InternalComponent,
    resolve: [
      OrganizationsResolverService,
      CurrentOrganizationResolverService
    ],
    children: [
      {path: 'livetracking', component: LivetrackingComponent, canActivate: [AdminGuard]},
      {
        path: 'organizations',
        loadChildren: () => import('./pages/organizations/organizations.module').then(m => m.OrganizationsModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/users/users.component.module').then(m => m.UsersModule),
      },
      {
        path: 'trackings',
        loadChildren: () => import('./pages/tracking-organizations/tracking-organizations.module').then(m => m.TrackingOrganizationsModule),
      },
      {
        path: 'kiosk',
        loadChildren: () => import('./pages/kiosk-organizations/kiosk-organizations.module').then(m => m.KioskOrganizationsModule),
      },
      {
        path: 'widget',
        loadChildren : () => import('./pages/widget/widget.module').then(m => m.WidgetModule)
      },
      {path: 'geco-pos', component: GecoPosOrganizationsComponent, canActivate: [AdminGuard], pathMatch: 'full'},
      {
        path: 'user-actions',
        loadChildren : () => import('./pages/user-actions/user-actions.module').then(m => m.UserActionsModule),
      },
      {path: 'notfound', component: NotFoundComponent},
      {
        path: 'report',
        loadChildren : () => import('./pages/report/report.module').then(m => m.ReportModule),
      },
      {
        matcher: (url): UrlMatchResult => {
          if (url.length === 0) {
            return {
              consumed: [
                new UrlSegment('', {})
              ],
              posParams: {
                currentOrganizationId: new UrlSegment('', {})
              }
            };
          } else {
            return {
              consumed: [url[0]],
              posParams: {
                currentOrganizationId: new UrlSegment(url[0].path, {})
              }
            };
          }
        },
        component: OrganizationPagesComponent,
        resolve: [
          CurrentOrganizationResolverService,
          CurrentOrganizationPipesResolverService
        ],
        children: [
          {
            path: 'order',
            children: [
              {
                path: 'list',
                resolve: {
                  orders: OrdersListResolverService,
                },
                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                children: [
                  {
                    path: ':id',
                    resolve: {
                      currentOrder: CurrentOrderResolverService
                    },
                    component: OrderListComponent
                  },
                  {path: '**', component: OrderListRedirectComponent}
                ]
              },
              {
                path: 'new',
                loadChildren: () => import('./pages/master-orders/master-orders.module').then(m => m.MasterOrdersModule),
              },
              {
                path: 'master-list',
                loadChildren: () => import('./pages/master-order-list/master-order-list.module').then(m => m.MasterOrderListModule),
              },
              {
                path: 'details',
                children: [
                  {
                    path: ':id',
                    resolve: {
                      currentOrder: CurrentOrderResolverService
                    },
                    component: OrderListDetailsComponent,
                  }
                ]
              },
              {
                path: '**', redirectTo: 'new'
              }
            ]
          },
          {
            path: 'dashboard',
            loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
          },
          {
            path: 'menu',
            resolve: [MenuRouteResolverService],
            loadChildren : () => import('./pages/menu/menu.module').then(m => m.MenuModule),
            canActivate: [ManagerGuard]
          },
          {
            path: 'menu-events',
            pathMatch: 'full',
            loadChildren : () => import('./pages/menu-events/menu-events.module').then(m => m.MenuEventsModule),
          },
          {
            path: 'location',
            loadChildren : () => import('./pages/location/location.module').then(m => m.LocationModule)
          },
          {path: '**', redirectTo: 'order'},
        ]
      },
    ],
  },
  {
    path: 'auth',
    loadChildren : () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {path: '**', redirectTo: 'app'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      relativeLinkResolution: 'legacy'
    }),
  ],
  exports: [RouterModule],
})

export class AppRoutingModule {
}
