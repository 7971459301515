import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';
import { saveAs } from 'file-saver';
import { OrderReportType } from 'app/pages/report/report.component';
import { IOrdersReportFilter } from 'app/models/report.model';

@Injectable({
  providedIn: 'root',
})
export class ApiReportService {
  private headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient) {
  }

  public async GetOrdersReport(ordersReportFilter: IOrdersReportFilter)
  {
    const reportUrl = `${environment.baseUrl}/api/report/orders`;

    const responseBlob = await firstValueFrom(
      this.http.post(
        reportUrl,
        ordersReportFilter,
        {
          headers: this.headers,
          responseType: 'blob',
          observe: 'response',
        }));

    saveAs(responseBlob.body, 'order_report.xlsx');
  }
}
