/**
 * Важно!!! Путь должен начинаться с '/' или 'https://'
 * Важно!!! Путь не должен заканчиваться '/'
 * http://domain.name - правильно
 * http://domain.name/uri - правильно
 * http://domain.name/uri/ - НЕ правильно
 * /uri/ - НЕ правильно
 * /uri - правильно
 */
export const environment = {
    production: true,
    hmr: false,
    baseUrl: 'https://hub.staging.open-s.info',
    env: 'staging',
    gMapApiKey: 'AIzaSyCz5iS1OOsPRFifhODTGm9elD84e-aMN90',
    posBaseUrl: 'https://pos-staging.open-s.info',
    livetrackingBaseUrl: 'https://api-track.ru.ge-co.io',
    widgetBaseUrl: 'https://widget.staging.open-s.info',
    kioskBaseUrl : 'https://kiosk-staging.open-s.info',
    lastHashCommit : '0cc96812a56ffe957224e76b80cecd3a17e1bb33'
};
