<div class="wrapper">
  <div [@openClose]="isOpen ? 'open' : 'closed'" [class]="(isOpen ? '' : 'close ') + 'sidebar'">
    <div class="menu-toggler">
      <button (click)="toggleMenu()" mat-button>
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
    </div>

    <div class="logo">
      <div class="logo-mini">
        <div class="logo-img">

          <img alt="" class="hide-on-close" src="./assets/images/logos/logo.png"/>
        </div>
      </div>
      <span class="logo-normal"></span>
    </div>

    <div class="sidebar-wrapper">
      <ul *ngIf="selectedOrganizationId$ | async as selectedOrganization" class="nav">
        <li class="nav-item" routerLinkActive="active">
          <a [routerLink]="['/app', selectedOrganization, 'order', 'new']" class="nav-link" mat-button>
            <mat-icon>remove_red_eye</mat-icon>
            <span class="hide-on-close link-title">{{ 'NAV.ORDERS_NEW' | translate }}</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a [routerLink]="['/app', selectedOrganization, 'order', 'list']" class="nav-link" mat-button>
            <mat-icon>list</mat-icon>
            <span class="hide-on-close link-title">{{ 'NAV.ORDERS_LIST' | translate }}</span>
          </a>
        </li>
        <li *ngIf="roleMembership.isSupport" class="nav-item" routerLinkActive="active">
          <a [routerLink]="['/app', selectedOrganization, 'order', 'master-list']" class="nav-link" mat-button>
            <mat-icon>list</mat-icon>
            <span class="hide-on-close link-title">{{ 'NAV.ORDERS_MASTER_LIST' | translate }}</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <a [routerLink]="['/app', selectedOrganization, 'location']" class="nav-link" mat-button>
            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <g id="Group_343" opacity="0.8">
                <g id="Group_342">
                  <path class="cls-3"
                        d="M15.625 10A3.625 3.625 0 1 0 12 13.625 3.629 3.629 0 0 0 15.625 10zm-6 0A2.375 2.375 0 1 1 12 12.375 2.377 2.377 0 0 1 9.625 10z"
                        id="Path_105"/>
                  <path class="cls-3"
                        d="M18.5 21.375h-4.569c2.413-1.909 6.694-6.043 6.694-11.375a8.625 8.625 0 0 0-17.25 0c0 7.263 7.955 12.318 8.294 12.53.011.007.024 0 .035.01a.6.6 0 0 0 .3.085h6.5a.625.625 0 0 0 0-1.25zM4.625 10a7.375 7.375 0 0 1 14.75 0c0 5.773-5.9 10.225-7.375 11.248C10.523 20.225 4.625 15.773 4.625 10z"
                        id="Path_106"/>
                </g>
              </g>
            </svg>
            <span class="hide-on-close link-title">{{ 'NAV.LOCATION' | translate }}</span>
          </a>
        </li>
        <li class="nav-item"
            routerLinkActive="active">
          <a [routerLink]="['/app', selectedOrganization, 'menu', 'main']" class="nav-link" mat-button>
            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <g id="Group_340" opacity="0.8">
                <path d="M0 0H24V24H0z" fill="none" id="Rectangle_267"/>
                <g id="Group_339">
                  <path class="cls-3"
                        d="M18 2.375H6A2.629 2.629 0 0 0 3.375 5v13.5a2.622 2.622 0 0 0 1.961 2.529c.006 0 .01.007.017.008l.038.009c.04.01.079.022.119.03l10.464 2.545a2.626 2.626 0 0 0 3.151-2.572v-.114c0-.024-.011-.043-.013-.066a2.621 2.621 0 0 0 1.513-2.369V5A2.629 2.629 0 0 0 18 2.375zM16.246 22.4L11 21.125h6.866a1.367 1.367 0 0 1-1.62 1.275zm3.129-3.9A1.377 1.377 0 0 1 18 19.875H6a1.278 1.278 0 0 1-.233-.024l-.12-.029A1.373 1.373 0 0 1 4.625 18.5V5A1.377 1.377 0 0 1 6 3.625h12A1.377 1.377 0 0 1 19.375 5z"
                        id="Path_101"/>
                  <path class="cls-3"
                        d="M11.5 4.375a.625.625 0 0 0-.625.625v4a1.375 1.375 0 0 1-2.75 0V5a.625.625 0 0 0-1.25 0v4a2.614 2.614 0 0 0 1.061 2.1v6.43a1.595 1.595 0 1 0 3.189 0v-6.485a2.611 2.611 0 0 0 1-2.045V5a.625.625 0 0 0-.625-.625zM9.875 17.53a.345.345 0 1 1-.689 0v-5.937a2.492 2.492 0 0 0 .314.032 2.59 2.59 0 0 0 .375-.038z"
                        id="Path_102"/>
                  <path class="cls-3"
                        d="M9.5 9.625A.625.625 0 0 0 10.125 9V5a.625.625 0 0 0-1.25 0v4a.625.625 0 0 0 .625.625z"
                        id="Path_103"/>
                  <path class="cls-3"
                        d="M13.779 4.441a.625.625 0 0 0-.9.559v12.719a1.625 1.625 0 0 0 1.621 1.626 1.627 1.627 0 0 0 1.629-1.626V15a1.626 1.626 0 0 0 1-1.5V9.854a6.019 6.019 0 0 0-3.35-5.413zm1.1 13.278a.375.375 0 0 1-.6.3.367.367 0 0 1-.145-.3v-2.594h.75zm1-4.219a.375.375 0 0 1-.375.375h-1.379V6.144a4.773 4.773 0 0 1 1.75 3.71z"
                        id="Path_104"/>
                </g>
              </g>
            </svg>
            <span class="hide-on-close link-title">{{ 'NAV.MENU' | translate }}</span>
          </a>
        </li>
        <ng-container *ngIf="roleMembership.isSupport">
          <li class="nav-item" routerLinkActive="active">
            <a [routerLink]="['/app', selectedOrganization, 'dashboard']" class="nav-link" mat-button>
              <svg height="24" id="Component_10_25" viewBox="0 0 24 24" width="24"
                   xmlns="http://www.w3.org/2000/svg">
                <g id="Group_350" opacity="0.8">
                  <path d="M0 0H24V24H0z" fill="none" id="Rectangle_273"/>
                  <path class="cls-3"
                        d="M11 11.625H4A2.629 2.629 0 0 1 1.375 9V6A2.629 2.629 0 0 1 4 3.375h7A2.629 2.629 0 0 1 13.625 6v3A2.629 2.629 0 0 1 11 11.625zm-7-7A1.377 1.377 0 0 0 2.625 6v3A1.377 1.377 0 0 0 4 10.375h7A1.377 1.377 0 0 0 12.375 9V6A1.377 1.377 0 0 0 11 4.625z"
                        id="Path_126"/>
                  <path class="cls-3"
                        d="M20 20.625h-7A2.629 2.629 0 0 1 10.375 18v-3A2.629 2.629 0 0 1 13 12.375h7A2.629 2.629 0 0 1 22.625 15v3A2.629 2.629 0 0 1 20 20.625zm-7-7A1.377 1.377 0 0 0 11.625 15v3A1.377 1.377 0 0 0 13 19.375h7A1.377 1.377 0 0 0 21.375 18v-3A1.377 1.377 0 0 0 20 13.625z"
                        id="Path_127"/>
                  <path class="cls-3"
                        d="M20 11.625h-3A2.629 2.629 0 0 1 14.375 9V6A2.629 2.629 0 0 1 17 3.375h3A2.629 2.629 0 0 1 22.625 6v3A2.629 2.629 0 0 1 20 11.625zm-3-7A1.377 1.377 0 0 0 15.625 6v3A1.377 1.377 0 0 0 17 10.375h3A1.377 1.377 0 0 0 21.375 9V6A1.377 1.377 0 0 0 20 4.625z"
                        id="Path_128"/>
                  <path class="cls-3"
                        d="M7 20.625H4A2.629 2.629 0 0 1 1.375 18v-3A2.629 2.629 0 0 1 4 12.375h3A2.629 2.629 0 0 1 9.625 15v3A2.629 2.629 0 0 1 7 20.625zm-3-7A1.377 1.377 0 0 0 2.625 15v3A1.377 1.377 0 0 0 4 19.375h3A1.377 1.377 0 0 0 8.375 18v-3A1.377 1.377 0 0 0 7 13.625z"
                        id="Path_129"/>
                </g>
              </svg>
              <span class="hide-on-close link-title">{{ 'NAV.DASHBOARD' | translate }}</span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a [routerLink]="['/app', 'report']" class="nav-link" mat-button>
              <svg height="24" id="Group_348" viewBox="0 0 24 24" width="24"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H24V24H0z" fill="none" id="Rectangle_272"/>
                <path class="cls-2"
                      d="M6.5 10.625A3.125 3.125 0 1 1 9.625 7.5 3.129 3.129 0 0 1 6.5 10.625zm0-5A1.875 1.875 0 1 0 8.375 7.5 1.877 1.877 0 0 0 6.5 5.625z"
                      id="Path_117"/>
                <path class="cls-2" d="M20 6.625h-5a.625.625 0 0 1 0-1.25h5a.625.625 0 0 1 0 1.25z"
                      id="Path_119"/>
                <path class="cls-2"
                      d="M12 6.63a.711.711 0 0 1-.24-.05.47.47 0 0 1-.2-.141.645.645 0 0 1-.14-.2.6.6 0 0 1 0-.48.7.7 0 0 1 .34-.34.631.631 0 0 1 .679.14.576.576 0 0 1 .141.2.7.7 0 0 1 .04.24.6.6 0 0 1-.181.439.608.608 0 0 1-.439.192z"
                      id="Path_120"/>
                <path class="cls-2" d="M20 9.625h-5a.625.625 0 0 1 0-1.25h5a.625.625 0 0 1 0 1.25z"
                      id="Path_122"/>
                <path class="cls-2"
                      d="M12 9.62a.6.6 0 0 1-.44-.181.645.645 0 0 1-.14-.2.687.687 0 0 1-.05-.239.593.593 0 0 1 .19-.44.632.632 0 0 1 .68-.14.554.554 0 0 1 .2.14.6.6 0 0 1 .18.44.615.615 0 0 1-.181.439.632.632 0 0 1-.2.141.7.7 0 0 1-.239.04z"
                      id="Path_123"/>
                <path class="cls-2"
                      d="M20 20.625H4a.625.625 0 0 1-.5-1l3-4a.63.63 0 0 1 .651-.231l3.574.893 1.754-2.634a.628.628 0 0 1 .672-.259l3.658.914 2.749-2.75a.625.625 0 0 1 .884.884l-3 3a.624.624 0 0 1-.593.164l-3.574-.893-1.754 2.634a.626.626 0 0 1-.672.259l-3.6-.9-2 2.668H20a.625.625 0 0 1 0 1.25z"
                      id="Path_124"/>
                <path class="cls-2" d="M9 13.625H4a.625.625 0 0 1 0-1.25h5a.625.625 0 0 1 0 1.25z"
                      id="Path_125"/>
              </svg>
              <span class="hide-on-close link-title">{{ 'NAV.REPORTS' | translate }}</span>
            </a>
          </li>
          <li [attr.disabled]="true" class="nav-item" routerLinkActive="active">
            <a class="nav-link" mat-button>
              <img alt="" height="24" src="/assets/icons/menu/orders.svg" width="24">
              <span class="hide-on-close link-title">{{ 'NAV.ORDERS' | translate }}</span>
            </a>
          </li>
          <li [attr.disabled]="true" class="nav-item" routerLinkActive="active">
            <a class="nav-link" mat-button>
              <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <g id="Group_338" opacity="0.8" transform="translate(-96)">
                  <path d="M0 0H24V24H0z" fill="none" id="Rectangle_266" transform="translate(96)"/>
                  <path class="cls-3"
                        d="M108 10.625A4.625 4.625 0 1 1 112.625 6 4.631 4.631 0 0 1 108 10.625zm0-8A3.375 3.375 0 1 0 111.375 6 3.379 3.379 0 0 0 108 2.625z"
                        id="Path_99"/>
                  <path class="cls-3"
                        d="M117 21.625a.625.625 0 0 1-.625-.625 8.375 8.375 0 0 0-16.75 0 .625.625 0 0 1-1.25 0 9.625 9.625 0 0 1 19.25 0 .625.625 0 0 1-.625.625z"
                        id="Path_100"/>
                </g>
              </svg>

              <span class="hide-on-close link-title">{{ 'NAV.CLIENTS' | translate }}</span>
            </a>
          </li>
          <li
            class="nav-item small" routerLinkActive="active">
            <a [relativeTo]="route" class="nav-link" mat-button routerLink="organizations">
              <svg height="24"
                   viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <g id="surface1">
                  <path
                    d="M 23.121094 14.117188 L 20.390625 14.117188 L 20.390625 12.136719 C 20.390625 11.652344 19.996094 11.257812 19.511719 11.257812 L 12.878906 11.257812 L 12.878906 9.792969 C 14.933594 9.386719 16.488281 7.566406 16.488281 5.394531 C 16.488281 2.917969 14.476562 0.902344 12 0.902344 C 9.523438 0.902344 7.511719 2.917969 7.511719 5.394531 C 7.511719 7.566406 9.066406 9.386719 11.121094 9.792969 L 11.121094 11.257812 L 4.488281 11.257812 C 4.003906 11.257812 3.609375 11.652344 3.609375 12.136719 L 3.609375 14.117188 L 0.878906 14.117188 C 0.394531 14.117188 0 14.511719 0 14.996094 L 0 22.21875 C 0 22.703125 0.394531 23.09375 0.878906 23.09375 L 8.097656 23.09375 C 8.585938 23.09375 8.976562 22.703125 8.976562 22.21875 L 8.976562 14.996094 C 8.976562 14.511719 8.585938 14.117188 8.097656 14.117188 L 5.367188 14.117188 L 5.367188 13.011719 L 18.632812 13.011719 L 18.632812 14.117188 L 15.902344 14.117188 C 15.414062 14.117188 15.023438 14.511719 15.023438 14.996094 L 15.023438 22.21875 C 15.023438 22.703125 15.414062 23.097656 15.902344 23.097656 L 23.121094 23.097656 C 23.605469 23.097656 24 22.703125 24 22.21875 L 24 14.996094 C 24 14.511719 23.605469 14.117188 23.121094 14.117188 Z M 7.222656 15.875 L 7.222656 21.339844 L 1.757812 21.339844 L 1.757812 15.875 Z M 9.265625 5.394531 C 9.265625 3.886719 10.492188 2.660156 12 2.660156 C 13.507812 2.660156 14.734375 3.886719 14.734375 5.394531 C 14.734375 6.898438 13.507812 8.125 12 8.125 C 10.492188 8.125 9.265625 6.898438 9.265625 5.394531 Z M 22.242188 21.339844 L 16.777344 21.339844 L 16.777344 15.875 L 22.242188 15.875 Z M 22.242188 21.339844 "/>
                </g>
              </svg>
              <span class="hide-on-close link-title">{{ 'NAV.ORGANIZATIONS' | translate }}</span>
            </a>
          </li>
          <li
            class="nav-item small" routerLinkActive="active">
            <a class="nav-link" mat-button routerLink="users">
              <svg height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.5 13c-1.2 0-3.07.34-4.5 1-1.43-.67-3.3-1-4.5-1C5.33 13 1 14.08 1 16.25V19h22v-2.75c0-2.17-4.33-3.25-6.5-3.25zm-4 4.5h-10v-1.25c0-.54 2.56-1.75 5-1.75s5 1.21 5 1.75v1.25zm9 0H14v-1.25c0-.46-.2-.86-.52-1.22.88-.3 1.96-.53 3.02-.53 2.44 0 5 1.21 5 1.75v1.25zM7.5 12c1.93 0 3.5-1.57 3.5-3.5S9.43 5 7.5 5 4 6.57 4 8.5 5.57 12 7.5 12zm0-5.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm9 5.5c1.93 0 3.5-1.57 3.5-3.5S18.43 5 16.5 5 13 6.57 13 8.5s1.57 3.5 3.5 3.5zm0-5.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z"/>
              </svg>
              <span class="hide-on-close link-title">{{ 'NAV.USERS' | translate }}</span>
            </a>
          </li>
          <li
            class="nav-item small" routerLinkActive="active">
            <a [relativeTo]="route" class="nav-link" mat-button routerLink="trackings">
              <svg height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path
                  d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"/>
              </svg>
              <span class="hide-on-close link-title">{{ 'NAV.TRACKING_ORGANIZATIONS' | translate }}</span>
            </a>
          </li>
          <li
            class="nav-item small" routerLinkActive="active" *ngIf="isWidgetAvailable">
            <a [relativeTo]="route" class="nav-link" mat-button routerLink="widget">
              <svg height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path
                  d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-5 14H4v-4h11v4zm0-5H4V9h11v4zm5 5h-4V9h4v9z"/>
              </svg>
              <span class="hide-on-close link-title">{{ 'NAV.WIDGET' | translate }}</span>
            </a>
          </li>
          <li
            class="nav-item small" routerLinkActive="active">
            <a [relativeTo]="route" class="nav-link" mat-button routerLink="geco-pos">
              <svg height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path
                  d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-5 14H4v-4h11v4zm0-5H4V9h11v4zm5 5h-4V9h4v9z"/>
              </svg>
              <span class="hide-on-close link-title">{{ 'NAV.GECO_POS' | translate }}</span>
            </a>
          </li>

          <li *ngIf="roleMembership.isSupport" class="nav-item small" routerLinkActive="active">
            <a mat-button class="nav-link" routerLink="kiosk" [relativeTo]="route">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path
                  d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-5 14H4v-4h11v4zm0-5H4V9h11v4zm5 5h-4V9h4v9z"/>
              </svg>
              <span class="hide-on-close link-title">{{ 'NAV.KIOSK_ORGANIZATIONS' | translate }}</span>
            </a>
          </li>

          <li *ngIf="roleMembership.isSupport" class="nav-item small" routerLinkActive="active">
            <a [relativeTo]="route" class="nav-link" mat-button routerLink="user-actions">
              <svg height="24px" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path
                  d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-5 14H4v-4h11v4zm0-5H4V9h11v4zm5 5h-4V9h4v9z"/>
              </svg>
              <span class="hide-on-close link-title">{{ 'NAV.USER_ACTIONS' | translate }}</span>
            </a>
          </li>
        </ng-container>
      </ul>

      <div>
        <ul class="nav-bottom">
          <li *ngIf="roleMembership.isSupport" [attr.disabled]="true" class="nav-item" routerLinkActive="active">
            <a class="nav-link" mat-button>
              <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.8">
                  <path
                    d="M20.183 17.2a4.607 4.607 0 0 1-.625-.994l-.053-.132a26.486 26.486 0 0 1-1.38-4.642l-.7-3.5a5.6 5.6 0 0 0-3.8-4.3V3a1.625 1.625 0 0 0-3.25 0v.646A5.345 5.345 0 0 0 6.6 7.8l-.728 3.638A26.569 26.569 0 0 1 4.5 16.076l-.055.135a4.773 4.773 0 0 1-.648 1.025 2.114 2.114 0 0 0-.4 1.536 2.18 2.18 0 0 0 2.2 1.853h.254a1.621 1.621 0 0 0 3.159.479l.16-.479h9.237a2.18 2.18 0 0 0 2.2-1.852 2.14 2.14 0 0 0-.424-1.573zM11.625 3a.375.375 0 0 1 .75 0v.408a5.518 5.518 0 0 0-.75.006zm-3.8 17.707a.373.373 0 0 1-.188.217.375.375 0 0 1-.5-.167.35.35 0 0 1-.038-.132h.751zm10.583-1.332H5.595a.943.943 0 0 1-.962-.76.883.883 0 0 1 .185-.662c.08-.106.156-.219.234-.328H16a.625.625 0 0 0 0-1.25H5.719A27.941 27.941 0 0 0 7.1 11.683l.727-3.638A4.251 4.251 0 0 1 12 4.625a4.39 4.39 0 0 1 .948.1A4.331 4.331 0 0 1 16.2 8.178l.7 3.5a27.956 27.956 0 0 0 1.447 4.862l.052.132a5.812 5.812 0 0 0 .807 1.308.855.855 0 0 1 .163.632.943.943 0 0 1-.964.763z"/>
                </g>
              </svg>
              <span class="hide-on-close link-title">{{ 'NAV.NOTIFICATIONS' | translate }}</span>
            </a>
          </li>
          <li *ngIf="roleMembership.isSupport" [attr.disabled]="!roleMembership.isSupport"
              [routerLinkActiveOptions]="{ exact: true }" class="nav-item" routerLinkActive="active">
            <a [routerLink]="['/app', selectedOrganizationId$ | async, 'location', 'configurations']" class="nav-link"
               mat-button>
              <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <g id="Group_345" opacity="0.8">
                  <g id="Group_344">
                    <path class="cls-3"
                          d="M6 11.375a.625.625 0 0 0-.625.625v8a.625.625 0 0 0 1.25 0v-8A.625.625 0 0 0 6 11.375z"
                          id="Path_108"/>
                    <path class="cls-3"
                          d="M12 18.875a.625.625 0 0 0-.625.625v.5a.625.625 0 0 0 1.25 0v-.5a.625.625 0 0 0-.625-.625z"
                          id="Path_109"/>
                    <path class="cls-3"
                          d="M6.625 5.458V4a.625.625 0 0 0-1.25 0v1.458a2.625 2.625 0 0 0-2 2.542v.5a2.625 2.625 0 0 0 5.25 0V8a2.625 2.625 0 0 0-2-2.542zm.75 3.042a1.375 1.375 0 0 1-2.75 0V8a1.375 1.375 0 0 1 2.75 0z"
                          id="Path_110"/>
                    <path class="cls-3"
                          d="M12.625 12.958V4a.625.625 0 0 0-1.25 0v8.958a2.625 2.625 0 0 0-2 2.542v.5a2.625 2.625 0 0 0 5.25 0v-.5a2.625 2.625 0 0 0-2-2.542zm.75 3.042a1.375 1.375 0 0 1-2.75 0v-.5a1.375 1.375 0 0 1 2.75 0z"
                          id="Path_111"/>
                    <path class="cls-3"
                          d="M18.625 8.458V4a.625.625 0 0 0-1.25 0v4.458a2.625 2.625 0 0 0-2 2.542v.5a2.625 2.625 0 0 0 5.25 0V11a2.625 2.625 0 0 0-2-2.542zm.75 3.042a1.375 1.375 0 0 1-2.75 0V11a1.375 1.375 0 0 1 2.75 0z"
                          id="Path_112"/>
                    <path class="cls-3"
                          d="M18 14.375a.625.625 0 0 0-.625.625v5a.625.625 0 0 0 1.25 0v-5a.625.625 0 0 0-.625-.625z"
                          id="Path_113"/>
                  </g>
                </g>
              </svg>
              <span class="hide-on-close link-title">{{ 'NAV.CONFIGURATIONS' | translate }}</span>
            </a>
          </li>
          <li *ngIf="roleMembership.isSupport" [attr.disabled]="true" class="nav-item" routerLinkActive="active">
            <a class="nav-link" mat-button>
              <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.8">
                  <path
                    d="M22.625 14a4.612 4.612 0 0 0-2.5-4.086 8.121 8.121 0 0 0-16.242 0A4.61 4.61 0 0 0 6 18.625.625.625 0 0 0 6.625 18v-8a5.375 5.375 0 0 1 10.75 0v7.5a2.372 2.372 0 0 1-1.8 2.3c-.009-.05-.012-.1-.025-.151a1.619 1.619 0 0 0-.758-.987l-.866-.5a1.625 1.625 0 1 0-1.626 2.812l.865.5a1.6 1.6 0 0 0 2.046-.37 3.61 3.61 0 0 0 3.232-2.524A4.616 4.616 0 0 0 22.625 14zM14.3 20.253a.376.376 0 0 1-.513.138l-.865-.5a.376.376 0 0 1 .188-.7.383.383 0 0 1 .187.05l.865.5a.377.377 0 0 1 .175.229.369.369 0 0 1-.037.283zm-8.925-2.936a3.375 3.375 0 0 1 0-6.634v6.634zM12 3.375a6.629 6.629 0 0 0-6.6 6.06c-.084.011-.17.011-.252.026a6.869 6.869 0 0 1 13.7 0c-.082-.015-.168-.015-.252-.026A6.629 6.629 0 0 0 12 3.375zm6.625 13.942v-6.634a3.375 3.375 0 0 1 0 6.634z"/>
                </g>
              </svg>
              <span class="hide-on-close link-title">{{ 'NAV.SUPPORT' | translate }}</span>
            </a>
          </li>
        </ul>
        <mat-divider></mat-divider>

        <div class="bottom-info">

          <div #menuTrigger *ngIf="user" [matMenuTriggerFor]="menu" class="profile-info">
            <div
              style="display: flex; justify-content: flex-start; align-items: center; gap: 8px; flex-wrap: wrap;">
              <span class="i">{{user.lastName[0]}}{{user.firstName[0]}}</span>
              <span
                class="hide-on-close username">{{user.lastName}} {{user.firstName}}</span>
            </div>
            <mat-icon class="hide-on-close"
                      style="color: #fff; opacity: .4; font-size: 24px; width: 21px; height: 21px;">
              arrow_drop_down
            </mat-icon>
          </div>

          <mat-menu #menu="matMenu">
            <button
              (click)="setLanguage(lang)"
              *ngFor="let lang of languages"
              mat-menu-item style="display: flex; align-items: center;">
              <img [src]="'assets/icons/flags/'+lang.flag+'.png'" alt="" class="flag mr-16"
                   style="margin-right: 4px;">
              <span class="iso">{{lang.title}}</span>
            </button>
            <button (click)="logout()" mat-menu-item>
              <mat-icon style="margin-right: 4px;">exit_to_app</mat-icon>
              <span>{{ 'COMMON.LOGOUT' | translate }}</span>
            </button>
          </mat-menu>

          <mat-form-field style="margin-top: 20px;">
            <mat-label class="hide-on-close">{{ 'NAV.ORGANIZATION' | translate }}</mat-label>
            <mat-select (selectionChange)="onOrganizationChange()" [(value)]="selectedOrganizationId"
                        [disableRipple]="(organizations$ | async).length === 1"
                        [disabled]="(organizations$ | async).length === 1"
                        panelClass="panel-form">
              <mat-form-field appearance="outline" class="search-input">
                <input (keydown)="$event.stopPropagation()" (keyup)="this.filterValueChange()"
                       [(ngModel)]="filterValue"
                       [placeholder]="'COMMON.SEARCH' | translate" autocomplete="off"
                       matInput style="font-size: 12px;">
              </mat-form-field>
              <mat-option *ngFor="let o of this.filteredOrganizations" [id]="o.id" [value]="o.id">
                <span *ngIf="o.parentId">&nbsp;&nbsp;&nbsp;&nbsp;</span>{{o.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

    </div>
  </div>
  <div [@openClose]="isOpen ? 'openpanel' : 'closedpanel'" class="main-panel">
    <router-outlet></router-outlet>
  </div>
</div>
